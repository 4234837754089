
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<form @submit.prevent="addFileAttachments" autocomplete="off">
			<v-layout row wrap class="mb-8">

				<v-flex xs12 lg4 xl4 md4 sm4>

					<v-btn :color="img_file == null ? 'secondary' : 'teal'" :outlined="img_file == null" :loading="loading_btn" large style="width:100%" @click="openFile">
						Choose
						<!-- <v-icon>fas fa-check</v-icon> -->
					</v-btn>
				</v-flex>
				<v-flex xs12 lg1 xl1 md3 sm4>
					<input type="file" @change="processFile" ref="file" style=" display:none" />
				</v-flex>
				<v-flex xs12 lg4 xl4 md4 sm4>
					<v-btn color="primary" :loading="loading_btn" type="submit" v-if="img_file != null" large style="width:100%">Upload</v-btn>
				</v-flex>
            </v-layout>
			</form>
			<v-layout row wrap class="mb-8">
				<v-flex xs12 lg4 xl4 md12 sm12 v-for="item,index in fileRows" :key="index">

					<div class="mx-3" @click="downloadImage(item.file_attachment_path)">
						<v-img max-width="800" v-bind:src="$imageURL + 'files/image/' + item.file_attachment_path" />
					</div>
					<!-- add download btn for that image -->
					<!-- download -->
					<div class="mx-3">

						<!-- <v-btn class="mx-3" @click="downloadImage(item.file_attachment_path)" color="primary">
							 Open
                             <v-icon>mdi-open-in-new</v-icon>
						</v-btn> -->
						<v-btn class="" @click="selectedFileAttachments(item)" color="red">
							<v-icon>mdi-delete</v-icon>
							Delete
						</v-btn>
					</div>
				</v-flex>
				<br>

			</v-layout>

		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.file_attachments.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.file_attachments.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteFileAttachments(selected_file_attachments)">
						{{$store.getters.language.data.file_attachments.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/file_attachments.request.js'
	export default {
		data() {
			return {
				fileRows: [],
				working_hours: [],
				file_attachments: {},
				img_file: null,
				search: '',
				loading: false,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_file_attachments: {},
				delete_dialog: false,

			}
		},
		computed: {
			// employees(){
			//     return this.$store.getters.employees_list
			// },
			// users(){
			//     return this.$store.getters.users_list
			// },
			user() {
				return this.$store.getters.user
			},
		},
		mounted() {
            			this.id = this.$route.params.id

			this.readFileAttachments(this.id);
		},
		methods: {
			openFile() {
				this.$refs.file.click();
			},
			processFile($event) {
				this.img_file = $event.target.files[0];
			},
			addFileAttachments() {
				this.loading_btn = true
				var formData = new FormData();
				formData.append('employee_id', this.id);
				formData.append('user_id', this.user.user_id);
				formData.append('file_attachment_path', this.img_file);

				requests.createFileAttachments(formData).then(r => {
					if (r.status == 200) {
						this.readFileAttachments(this.id)
						this.file_attachments = {}
						this.file_attachments.user_id = this.user.user_id
						this.file_attachments.employee_id = this.id
						this.snackbar = {
							value: true,
							text: 'fileAttachments Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add fileAttachments',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			readFileAttachments(i) {
				this.loading = true
				var item_id = i.shop_item_id
				requests.getFileAttachmentsByColumn('employee_id', i).then(r => {
					if (r.status == 200) {
						this.fileRows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read FileAttachments',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read FileAttachments',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},

			deleteFileAttachments(file_attachment_id) {
				requests.deleteFileAttachments(file_attachment_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.file_attachment_id != file_attachment_id
						})
						this.snackbar = {
							value: true,
							text: 'FileAttachments Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteFileAttachmentsList() {
				let ids = this.selected_rows.map(m => m.file_attachment_id)
				requests.deleteFileAttachmentsList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.file_attachment_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' FileAttachments Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},
			downloadImage(fileAttachmentPath) {
				// Assuming the image path is relative to your API or server
				const imageURL = `${this.$imageURL}files/image/${fileAttachmentPath}`;
				const link = document.createElement('a');
				link.href = imageURL;
				link.target = "_blank";
				link.download = "image_download";
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			},
			deleteFileAttachments(file_attachment_id) {
				this.loading = true
				requests.deleteFileAttachments(file_attachment_id).then(r => {
					if (r.status == 200) {
						this.snackbar = {
							value: true,
							text: 'FileAttachments Deleted',
							color: 'success'
						}
                        this.delete_dialog = false
						this.readFileAttachments(this.id)
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to delete FileAttachments',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to delete FileAttachments',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectedFileAttachments(item) {
				this.selected_file_attachments = item.file_attachment_id
				this.delete_dialog = true
			},

		},
	}
</script>
                    